import React from "react";
import { rondola, rondola4, rondola5 } from "../../../assets";
import ImageSlider from "../../../utils/image-slider/image-slider";
import Reveal from "../../../utils/motion/reveal";
import Footer from "../../Footer/Footer";
import myPDF from "../../../assets/spring2024.pdf";

const NewsPage2 = () => {
  return (
    <div>
      <div className="page-container">
        <div
          className="page-default-banner"
          style={{
            backgroundImage: `url(${rondola4})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            padding: "145px 0",
          }}
        >
          <div className="article">
            <div className="row-article">
              <h1>
                <span class="article-title">Spring update 2024</span>
              </h1>
              <div className="article-info">
                <div className="article-data">
                  <span>By Ligia Pintican</span>
                </div>
                <div className="article-data">24th May 2024</div>
              </div>
            </div>
          </div>
        </div>

        <section className="section-container pd-t-40">
          <div className="article-content-row article-width">
            <div className="section-col1">
              <iframe
                src={myPDF}
                className="article-frame"
                title="PDF Viewer"
              />
            </div>

            <div className="section-col2">
              <div className="sidebar_widget has_border mb-40">
                <div className="sidebar_title">
                  <h4 class="sidebar_title_text has_border">
                    <span></span>Latest News
                  </h4>
                </div>

                <div className="feed_widget-content">
                  <div class="single_feed_widget has_border">
                    <div class="feed_widget_img">
                      <a href="/blog-details">
                        <img src={rondola5} alt="img" />
                      </a>
                    </div>
                    <div class="feed_widget_text">
                      <h5 class="feed_widget_title theme-1">
                        <a href="/">Spread your wings and fly</a>
                      </h5>
                      <a class="feed_widget_date theme-1" href="/blog-details#">
                        <i class="fal fa-calendar-alt"></i>24th August 2024
                      </a>
                    </div>
                  </div>
                  <div class="single_feed_widget has_border">
                    <div class="feed_widget_img">
                      <a href="/blog-details">
                        <img src={rondola4} alt="img" />
                      </a>
                    </div>
                    <div class="feed_widget_text">
                      <h5 class="feed_widget_title theme-1">
                        <a href="/">Spring update 2024</a>
                      </h5>
                      <a class="feed_widget_date theme-1" href="/blog-details#">
                        <i class="fal fa-calendar-alt"></i>24th May 2024
                      </a>
                    </div>
                  </div>
                  {/* <div class="single_feed_widget has_border">
                            <div class="feed_widget_img">
                                <a href="/blog-details">
                                    <img src={rondola5} alt="img" />
                                </a>
                            </div>
                            <div class="feed_widget_text">
                                <h5 class="feed_widget_title theme-1">
                                    <a href="/">Spread your wings and fly</a>
                                </h5>
                                <a class="feed_widget_date theme-1" href="/blog-details#">
                                <i class="fal fa-calendar-alt"></i>24th August 2024
                                </a>
                            </div>
                        </div> */}
                  <div class="single_feed_widget">
                    <div class="feed_widget_img">
                      <a href="/blog-details">
                        <img src={rondola} alt="img" />
                      </a>
                    </div>
                    <div class="feed_widget_text">
                      <h5 class="feed_widget_title theme-1">
                        <a href="/">Warm Feet 2023</a>
                      </h5>
                      <a class="feed_widget_date theme-1" href="/blog-details#">
                        <i class="fal fa-calendar-alt"></i>20 January 2024
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default NewsPage2;
