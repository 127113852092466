import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import Reveal from "../../../utils/motion/reveal";
import { banner2, rondola, rondola4, rondola5, slidder3 } from "../../../assets";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import NewsCard from "../../Home/NewsCard";

import "../../styles/news-main.css";
import Footer from "../../Footer/Footer";
import { house1 } from "../../../assets/houseOfJoy";
import { banner } from "../../../assets/news-images/warm feet 2024";

const NewsMain = (props) => {
  const {t} = props;

  return (
    <div>
      <div className="page-container">
        <div>
          <div
            className="page-default-banner"
            style={{
              backgroundImage: `url(${slidder3})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              padding: "145px 0",
            }}
          >
            <div className="row">
                <h1>
                    <Reveal>
                        <span className="color-big-heading">
                            {t("News")}
                        </span>
                    </Reveal>
                </h1>

                <Reveal>
                <ul className="breadcrumb-wrapper">
                  <li className="breadcrumb-item">{t("News")}</li>
                  <li className="breadcrumb-item">
                    <ArrowForwardIcon></ArrowForwardIcon>
                  </li>
                  <li className="breadcrumb-item ">{t("News")}</li>
                </ul>
                </Reveal>
            </div>
          </div>
        </div>

        <section className="section-container pt-120">
            <div className="articles-row">

            <div className="article-col">
                <NewsCard 
                  title="Warm Feet 2024"
                  image={banner}
                  description={"Imagine a child's face lighting up as they receive a warm pair of boots."}
                  linkDestination={"/news/Warm-Feet-2024"}
                />
              </div>

            <div className="article-col">
                <NewsCard
                    title="Albesti summer camp"
                    image={house1}
                    description={"Families in unity and diversity!"}
                    linkDestination={"/news/Albesti-summer-camp"}
                />
              </div>

              <div className="article-col">
                <NewsCard
                    title="Spread your wings and fly"
                    image={rondola5}
                    description={"Summer Camp with Goldberich and Hoghilag children and teens"}
                    linkDestination={"/news/Spread-your-wings-and-fly"}
                />
              </div>
              <div className="article-col">
                <NewsCard 
                  title="Spring update 2024"
                  image={rondola4}
                  description={"A big thank you to all of you who have been giving towards the work in Sighisoara in different ways during 2024."}
                  linkDestination={"/news/Spring-update"}
                />
              </div>
              <div className="article-col">
                <NewsCard 
                  title="Warm Feet 2023"
                  image={rondola}
                  description={"Once again, the project was a blessing for thousands of children and families in need, as well was bringing comfort and help for those living in difficult circumstances around us."}
                  linkDestination={"/news/Warm-Feet-2023"}
                />
              </div>
            </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};


const enhance = compose(withTranslation("translation"));

export default enhance(NewsMain);
