import Navbar from './components/Navbar/Navbar';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Routes, Route, HashRouter } from "react-router-dom";


import './app.css';
import './components/styles/main.css';
import Home from './components/Home/Home';
import Teams from './components/About us/Teams';
import Footer from './components/Footer/Footer';
import DonationButton from './utils/custom/donationButton';
import Internship from './components/Get Involved/Internship';
import Sighisoara from './components/Projects/Sighisoara';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import Kindergarden from './components/How we work/Education/Kindergarden';
import Donation from './components/Donation/Donation';
import ScrollToTop from './utils/scrollToTop/scrollToTop';
import Albesti from './components/Projects/Albesti/Albesti';
import HouseOfJoy from './components/Projects/HouseOfJoy/HouseOfJoy';
import Daycare from './components/Projects/Daycare center/Daycare';
import SocialServices from './components/How we work/SocialServices/SocialServices';
import Contacts from './components/About us/contacts/Contacts';
import AfterSchool from './components/How we work/Education/AfterSchool';
import ChurchNetworking from './components/How we work/ChurchNetworking/ChurchNetworking';
import SundaySchool from './components/How we work/SundaySchool/SundaySchool';
import Outreach from './components/How we work/Outreaches&Camps/Outreach';
import Voluntering from './components/Get Involved/Voluntering/Voluntering';
import History from './components/About us/history/History';
import Vision from './components/About us/vision/Vision';
import Hogilag from './components/Projects/Hogilag/Hogilag';
import BuildingTeams from './components/Get Involved/BuildingTeams/BuildingTeams';
import NewsMain from './components/News/NewsMain/NewsMain';
import NewsPage1 from './components/News/News Page/newsPage1';
import NewsPage2 from './components/News/News Page/newsPage2';
import NewsPage3 from './components/News/News Page/newsPage3';
import NewsPage4 from './components/News/News Page/newsPage4';
import NewsPage5 from './components/News/News Page/newsPage5';



library.add(fas)

function App() {
  return (
    <HashRouter>
      <Navbar />
      <DonationButton />
      <ScrollToTop>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/team' element={<Teams />} />
        <Route path='/internship'element={<Internship />} />
        <Route path='/sighisoara' element={<Sighisoara />} />
        <Route path='/education/kindergarden' element={<Kindergarden />} />
        <Route path="/donation" element={<Donation />} />
        <Route path="/albesti" element={<Albesti />}/>
        <Route path="/house-of-joy" element={<HouseOfJoy />} />
        <Route path="/daycare" element={<Daycare />} />
        <Route path="/social-services" element={<SocialServices />} />
        <Route path="/contact" element={<Contacts />} />
        <Route path="/education/afterschool" element={<AfterSchool />} />
        <Route path="/church-networking" element={<ChurchNetworking />} />
        <Route path="/sunday-school" element={<SundaySchool />} />
        <Route path="/outreach" element={<Outreach />} />
        <Route path="/voluntering" element={<Voluntering />} />
        <Route path="/history" element={<History />} />
        <Route path="/vision" element={<Vision />} />
        <Route path="/hoghilag" element={<Hogilag />} />
        <Route path="/building" element={<BuildingTeams />} />
        <Route path="/news" element={<NewsMain />} />

        {/* This are hardcoded articles for now */}
        <Route path="/news/Warm-Feet-2023" element={<NewsPage1 />} />
        <Route path="/news/Spring-update" element={<NewsPage2 />} />
        <Route path="/news/Spread-your-wings-and-fly" element={<NewsPage3 />} />
        <Route path="/news/Albesti-summer-camp" element={<NewsPage4 />} />
        <Route path="/news/Warm-Feet-2024" element={<NewsPage5 />} />
      </Routes>
      </ScrollToTop>
      {/* <Footer /> */}
    </HashRouter>
  );
}

const enhance = compose(
  withTranslation('translation')
)

export default enhance(App);
